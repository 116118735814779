import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Slider from "react-slick";

// Styles
import "./who-we-serve.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import FAQ from "../components/FAQ";
import BookDiscussion from "../components/BookDiscussion";

// Images and icons
import WhoWeServeHero from "../assets/images/who-we-serve-hero.jpg";
import ServiceCardBG from "../assets/images/leading-card-bg.png";
import IconUsersWhite from "../assets/icons/icon-users-white.svg";
import IconUsersDark from "../assets/icons/icon-users-dark.svg";
import IconNeuroWhite from "../assets/icons/icon-neuro-white.svg";
import IconNeuroDark from "../assets/icons/icon-neuro-dark.svg";
import IconDecreaseWhite from "../assets/icons/icon-decrease-white.svg";
import IconDecreaseDark from "../assets/icons/icon-decrease-dark.svg";
import IconBookWhite from "../assets/icons/icon-book-white.svg";
import IconBookDark from "../assets/icons/icon-book-dark.svg";
import IconArchiveWhite from "../assets/icons/icon-archive-white.svg";
import IconArchiveDark from "../assets/icons/icon-archive-dark.svg";
import IconGlobeWhite from "../assets/icons/icon-globe-white.svg";
import IconGlobeDark from "../assets/icons/icon-globe-dark.svg";
import IconHonestyWhite from "../assets/icons/icon-honesty-white.svg";
import IconHonestyDark from "../assets/icons/icon-honesty-dark.svg";
import IconBankWhite from "../assets/icons/icon-bank-white.svg";
import IconBankDark from "../assets/icons/icon-bank-dark.svg";
import IconGuardWhite from "../assets/icons/icon-guard-white.svg";
import IconGuardDark from "../assets/icons/icon-guard-dark.svg";
import IconUserWhite from "../assets/icons/icon-user-white.svg";
import IconUserDark from "../assets/icons/icon-user-dark.svg";
import IconBarWhite from "../assets/icons/icon-bar-white.svg";
import IconBarDark from "../assets/icons/icon-bar-dark.svg";
import IconHandWhite from "../assets/icons/icon-hand-white.svg";
import IconHandDark from "../assets/icons/icon-hand-dark.svg";
import IconNoteWhite from "../assets/icons/icon-note-white.svg";
import IconNoteDark from "../assets/icons/icon-note-dark.svg";
import PartnerCorporateEdge from "../assets/images/partner-corporate-edge.png";
import PartnerHowden from "../assets/images/partner-howden.jpg";
import PartnerAndreFuller from "../assets/images/partner-andrew-fuller.jpg";
import AboutSolutionBG from "../assets/images/about-solution-bg.png";
import StandardOSHA from "../assets/images/standard-osha.png";
import StandardSWA from "../assets/images/standard-swa.png";
import StandardISO from "../assets/images/standard-iso.png";
import StandardWHO from "../assets/images/standard-who.png";
import StandardUSPHS from "../assets/images/standard-usphs.png";
import CarouselLeft from "../assets/images/carousel-left.png";
import CarouselRight from "../assets/images/carousel-right.png";
import RiskManagementProcess from "../assets/images/risk-management-process.png";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const StandardPrevArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <button className={`${"btn carousel-prev"} ${className}`} onClick={onClick}>
      <img src={CarouselLeft} alt={"Skodel"} />
    </button>
  );
};

const StandardNextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <button className={`${"btn carousel-next"} ${className}`} onClick={onClick}>
      <img src={CarouselRight} alt={"Skodel"} />
    </button>
  );
};

const OrganisationUseSkodelData = [
  {
    id: "OUS0001",
    imageWhite: IconUsersWhite,
    imageDark: IconUsersDark,
    title: "Schools and universities",
    description:
      "Home to a diverse population of students, teachers, and staff, all facing unique stressors. Hidden and unaddressed mental health concerns can escalate into serious issues and impact academic outcomes and reputation.",
  },
  {
    id: "OUS0002",
    imageWhite: IconNeuroWhite,
    imageDark: IconNeuroDark,
    title: "Risk management teams",
    description:
      "Psychosocial legislation is now in place and over 30% of all claims made against a business are mental health claims with 92% being paid out. This is a growing concern for teams defending their organisation against risks.",
  },
  {
    id: "OUS0003",
    imageWhite: IconDecreaseWhite,
    imageDark: IconDecreaseDark,
    title: "HR & wellbeing teams",
    description:
      "Mental ill health impacts absenteeism, turnover, quiet quitting and other key performance areas. HR teams that integrate mental health strategies into organisational culture reduce the impacts of mental ill health on performance metrics.",
  },
  {
    id: "OUS0004",
    imageWhite: IconBookWhite,
    imageDark: IconBookDark,
    title: "Corporate/business sector",
    description:
      "High pressure environment where mental wellbeing impacts culture, performance and turnover. Serious concerns that could be flagged and addressed early often go unreported, prolonging their impact.",
  },
  {
    id: "OUS0005",
    imageWhite: IconArchiveWhite,
    imageDark: IconArchiveDark,
    title: "Students in schools",
    description:
      "Academic pressures, isolation and increasing mental illnesses are prominent among students. Reluctance to communicate this face to face means minor concerns go undetected and can escalate into serious safety issues.",
  },
  {
    id: "OUS0006",
    imageWhite: IconGlobeWhite,
    imageDark: IconGlobeDark,
    title: "Leadership teams",
    description:
      "Increasingly expected to support and report on wellbeing but have limited visibility and support to do this. Stakeholders perceive lack of transparent reporting and tangible initiatives as neglecting wellbeing which impacts reputation.",
  },
];

const TeamUseSkodelData = [
  {
    id: "TUS0001",
    imageWhite: IconNeuroWhite,
    imageDark: IconNeuroDark,
    title: "Staff member has a hurtful interaction",
    description:
      "A person wants to flag a hurtful interaction with their manager but isn't ready to do this face-to-face. They do it on Skodel and get linked to supportive next steps.",
  },
  {
    id: "TUS0002",
    imageWhite: IconHonestyWhite,
    imageDark: IconHonestyDark,
    title: "Student experiencing repeated bullying",
    description:
      "A student fears retaliation and stigma reporting bullying. They report it anonymously on Skodel, access support lines and identify someone who can intervene.",
  },
  {
    id: "TUS0003",
    imageWhite: IconBankWhite,
    imageDark: IconBankDark,
    title: "Home life impacting performance",
    description:
      "A person is facing challenges at home impacting their focus. They share this privately on Skodel and access flexible work options to support such situations.",
  },
  {
    id: "TUS0004",
    imageWhite: IconGuardWhite,
    imageDark: IconGuardDark,
    title: "Considering leaving due to disinterest",
    description:
      "A person is considering leaving due to disinterest in work but isn't sure how to approach this. They privately report it on Skodel and get linked to book in a discussion.",
  },
];

const WhyUseSkodelData = [
  {
    id: "WUS0001",
    imageWhite: IconBarWhite,
    imageDark: IconBarDark,
    title: "Track impact of initiative on burnout",
    description:
      "A company notices an uptick in burnout, particularly among remote teams. They introduce a wellness day and review workloads. They track the number of people reporting burnout on Skodel before and after and notice an improvement.",
  },
  {
    id: "WUS0002",
    imageWhite: IconUserWhite,
    imageDark: IconUserDark,
    title: "Respond to serious wellbeing concerns",
    description:
      "A leader knows mental ill health is impacting performance but is unsure how to address sensitive issues, particularly at scale. They use Skodel to ensure every concern reported is linked to the right support such as their EAP and this is documented.",
  },
  {
    id: "WUS0003",
    imageWhite: IconHandWhite,
    imageDark: IconHandDark,
    title: "Resolve issues before they escalate",
    description:
      "Minor internal conflict is taking place but it's not being reported. An anonymous check-in confirms conflict and reveals people feel undervalued. Skodel suggests relevant next steps for each person to resolve their situation before it escalates.",
  },
  {
    id: "WUS0004",
    imageWhite: IconNoteWhite,
    imageDark: IconNoteDark,
    title: "Produce a transparent wellbeing report",
    description:
      "A leader faces growing expectations to demonstrate commitment to wellbeing. They use Skodel to collect wellbeing data and produce a report for key stakeholders outlining initiatives and their impact. This enhances reputation and trust.",
  },
];

const PartnerSliderData = [
  {
    id: "PS0001",
    title: "Our wellbeing strategy partner",
    description:
      "Our partner, Corporate Edge is highly experienced and are experts in leadership, organisational culture and behaviour change. Working with leading organisations, they've embedded sustainable, positive change across large multinational enterprises and many of Australia's top 200 businesses. Partners in workplace wellbeing, they have provided their expertise, as they recognise the need to understand how people feel and the drivers behind it. Helping leaders to create an environment of psychological safety for wellbeing to thrive, unlocking the potential of all individuals.",
    partner: "Corporate Edge, Established 1999",
    image: PartnerCorporateEdge,
  },
  {
    id: "PS0003",
    title: "Our people and risk partner",
    description:
      "Howden is one of the largest international brokerage firms in the word, providing People Risk advisory services across its global footprint. Howden is Skodel's advisory partner, helping clients eliminate or minimise people risks. Howden's expertise sees the application of global insights in a localised format, through person- centric solutions that meets the needs of our clients risk profile. With strong roots in injury prevention and management, Howden's team are experts in moving organisation from compliance to wellbeing economic change.",
    partner: "Howden (Australia), protecting people at work",
    image: PartnerHowden,
  },
  {
    id: "PS0003",
    title: "Our mental health partner",
    description:
      "Andrew has been described as someone who “puts the heart back into psychology”. As a clinical psychologist, Andrew works with organisations, families, young people and communities internationally, specialising in wellbeing. He is a Fellow of the Department of Psychiatry and the Department of Learning and Educational Development at the University of Melbourne. Andrew has been a principal consultant to the national drug prevention strategy REDI, the ABC on children's television shows, is an Ambassador for Mind Matters and is a member of the National Coalition Against Bullying.",
    partner: "Andrew Fuller, Clinical Psychologist",
    image: PartnerAndreFuller,
  },
];

const StandardSliderData = [
  {
    id: "SS0001",
    title: "ISO 45003",
    description:
      "ISO 45003 provides best practice guidance on proactively identifying, assessing and responding to psychosocial risks and hazards. Can be referenced by regulators.",
    image: StandardISO,
  },
  {
    id: "SS0002",
    title: "ISO 26000",
    description:
      "International standard that provides guidance on social responsibility. It offers a framework for organisations to integrate social responsibility into their operations.",
    image: StandardISO,
  },
  {
    id: "SS0003",
    title: "US Surgeon General",
    description:
      "Framework for Workplace Mental Health & Wellbeing as a response to the prioritisation of worker's voice and equity",
    image: StandardUSPHS,
  },
  {
    id: "SS0004",
    title: "SWA",
    description:
      "Safe Work Australia identifies 14 psychosocial risks and provides guidance on how to mitigate risks. Can be referenced by regulators in Australia.",
    image: StandardSWA,
  },
  {
    id: "SS0005",
    title: "Codes of Practice",
    description:
      "States in Australia have codes of practice which are admissible in court. These vary but generally focus on identifying, assessing and embedding control measures to reduce risk.",
    image: StandardSWA,
  },
  {
    id: "SS0006",
    title: "ISO 26000",
    description:
      "International standard that provides guidance on social responsibility. It offers a framework for organisations to integrate social responsibility into their operations.",
    image: StandardISO,
  },
  {
    id: "SS0007",
    title: "WHO",
    description:
      "WHO outlines 12 examples of mental health risks in the workplace and links to a comprehensive mental health action plan.",
    image: StandardWHO,
  },
  {
    id: "SS0008",
    title: "OSHA",
    description:
      "Provides guidance to employers on how to identify and address workplace stress and mental health challenges.",
    image: StandardOSHA,
  },
];

const FAQData = [
  {
    id: "FAQ0001",
    question:
      "What happens if someone discloses a psychological risk on Skodel?",
    answer:
      "The aim with Skodel is to embed your policies and procedures to be triggered to users if a risk is reported. This ensures sensitive issues are always safely responded to. Doing this digitally means leaders maintain an audit trail of issues reported and responses and support provided.",
  },
  {
    id: "FAQ0002",
    question:
      "What are my requirements as a leader from a psychosocial perspective?",
    answer:
      "While specifics may vary between regions, the general guidelines are commitment to identifying risks, implementing control measures to minimise or eliminate them and carrying out assessments to review and improve processes.",
  },
];

const WhoWeServe = () => {
  const [organisationUseSkodel, setOrganisationUseSkodel] = useState<
    {
      id: string;
      imageWhite: string;
      imageDark: string;
      title: string;
      description: string;
    }[]
  >([]);
  const [teamUseSkodel, setTeamUseSkodel] = useState<
    {
      id: string;
      imageWhite: string;
      imageDark: string;
      title: string;
      description: string;
    }[]
  >([]);
  const [whyUseSkodel, setWhyUseSkodel] = useState<
    {
      id: string;
      imageWhite: string;
      imageDark: string;
      title: string;
      description: string;
    }[]
  >([]);
  const [partnerSlide, setPartnerSlide] = useState<
    {
      id: string;
      title: string;
      description: string;
      partner: string;
      image: string;
    }[]
  >([]);
  const [standardSlide, setStandardSlide] = useState<
    { id: string; title: string; description: string; image: string }[]
  >([]);
  const [faq, setFAQ] = useState<
    { id: string; question: string; answer: string }[]
  >([]);

  useEffect(() => {
    setOrganisationUseSkodel(OrganisationUseSkodelData);
    setTeamUseSkodel(TeamUseSkodelData);
    setWhyUseSkodel(WhyUseSkodelData);
    setPartnerSlide(PartnerSliderData);
    setStandardSlide(StandardSliderData);
    setFAQ(FAQData);
  }, [
    setOrganisationUseSkodel,
    setTeamUseSkodel,
    setWhyUseSkodel,
    setPartnerSlide,
    setStandardSlide,
    setFAQ,
  ]);

  const partnersSliderSettings = {
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    infinite: true,
    fade: true,
    arrows: false,
    dots: true,
  };

  const standardSliderSettings = {
    slidesToShow: 4,
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <StandardPrevArrow />,
    nextArrow: <StandardNextArrow />,
  };

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        <title>Skodel</title>
        <meta
          property="og:title"
          content="Skodel - Report on psychological safety and support your people"
        />
        <meta
          property="og:description"
          content="Skodel's wellbeing and safety platform helps leaders with a higher duty of care meet requirements and support their people."
        />
      </Helmet>
      <Page>
        <section className="section-service-hero">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${WhoWeServeHero}')` }}
          ></div>
          <div className="main-container">
            <div className="service-hero-content">
              <h1>For leadership teams and people</h1>
              <p>
                Helping individuals and leaders by addressing complex mental
                health concerns inside your organisation for you and your
                people.
              </p>
              <div className="service-hero-button">
                <NavLink
                  to={"/request-a-demo"}
                  className="btn btn-white btn-hero-cta"
                >
                  Book A Demo
                </NavLink>
                <a
                  href="https://skodel.notion.site/Skodel-Meaningful-internal-feedback-20d06e097d0043e39cadbd30a408a4a0?pvs=4"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-white btn-hero-cta"
                >
                  Find Out More
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-service-middle">
          <div className="main-container">
            <div className="serve-content-wrapper">
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>Organisations and people that use Skodel</h2>
                  <p>
                    For organisations where challenges like burnout and turnover
                    are prevalent and where leaders face growing legal and
                    societal pressure to prevent cases of mental ill health.
                  </p>
                </div>
                <div className="group-value-wrapper">
                  <div className="group-value-grid">
                    {organisationUseSkodel?.map((item, index) => (
                      <div className="group-card" key={item?.id + "-" + index}>
                        <img src={ServiceCardBG} alt={"Skodel"} />
                        <div className="group-icon">
                          <img src={item?.imageWhite} alt={"Skodel"} />
                          <img src={item?.imageDark} alt={"Skodel"} />
                        </div>
                        <div className="group-description">
                          <h3>{item?.title}</h3>
                          <p>{item?.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>Example use cases for individuals</h2>
                  <p>
                    A private outlet to share wellbeing and access support for
                    those reluctant to do so because of stigma
                  </p>
                </div>
                <div className="service-value-grid column-4">
                  {teamUseSkodel?.map((item, index) => (
                    <div className="service-card" key={item?.id + "-" + index}>
                      <img src={ServiceCardBG} alt={"Skodel"} />
                      <div className="service-icon">
                        <img src={item?.imageWhite} alt={"Skodel"} />
                        <img src={item?.imageDark} alt={"Skodel"} />
                      </div>
                      <div className="service-description">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>Example use cases for leaders</h2>
                  <p>
                    Insights into important mental wellbeing matters, reports on
                    wellbeing impact and support to address wellbeing concerns
                  </p>
                </div>
                <div className="service-value-grid column-4">
                  {whyUseSkodel?.map((item, index) => (
                    <div className="service-card" key={item?.id + "-" + index}>
                      <img src={ServiceCardBG} alt={"Skodel"} />
                      <div className="service-icon">
                        <img src={item?.imageWhite} alt={"Skodel"} />
                        <img src={item?.imageDark} alt={"Skodel"} />
                      </div>
                      <div className="service-description">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="serve-partner-slider">
                <div className="section-title text-center">
                  <h2>How our partners can help you</h2>
                </div>
                <div className="partner-carousel">
                  <img src={AboutSolutionBG} alt={"Skodel"} />
                  <Slider {...partnersSliderSettings}>
                    {partnerSlide?.map((item, index) => (
                      <div key={item?.id + "-" + index}>
                        <div className="partner-wrapper">
                          <div className="partner-info">
                            <h3>{item?.title}</h3>
                            <p>{item?.description}</p>
                            <p>
                              <strong>{item?.partner}</strong>
                            </p>
                          </div>
                          <div className="partner-image">
                            <img src={item?.image} alt={item?.partner} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              <div className="serve-standard-slider">
                <div className="section-title text-center">
                  <h2>Legislation & International Standards</h2>
                  <p>
                    We aim to help you as a leader implement best practice for
                    good social governance in line with international standards
                    and relevant legislation
                  </p>
                </div>
                <div className="serve-standard">
                  <div className="standard-carousel">
                    <Slider {...standardSliderSettings}>
                      {standardSlide?.map((item, index) => (
                        <div key={item?.id + "-" + index}>
                          <div className="standard-card">
                            <div className="standard-icon">
                              <img src={item?.image} alt={item?.title} />
                            </div>
                            <h3>{item?.title}</h3>
                            <p>{item?.description}</p>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="serve-zigzag-content">
                <div className="serve-zigzag-wrapper">
                  <div className="serve-zigzag-image">
                    <img src={RiskManagementProcess} alt={"Skodel"} />
                  </div>
                  <div className="serve-zigzag-description">
                    <h3>Prioritising wellbeing, building trust</h3>
                    <p>
                      Tangible mental health initiatives build trust in your
                      commitment to wellbeing. Proactive check-ins build trust
                      in your commitment to wellbeing. When people genuinely
                      believe their wellbeing is cared for, they're less likely
                      to do wrong by the organisation. They're more inclined to
                      act ethically, responsibly and give their best effort.
                      And, external stakeholders who perceive commitment to
                      wellbeing are more likely to engage with and support the
                      organisation. Through genuine care, a positive culture and
                      optimal performance and engagement naturally emerge.
                    </p>
                  </div>
                </div>
              </div>
              <div className="serve-faq-wrapper">
                <div className="section-title text-center">
                  <h2>Frequently asked questions</h2>
                </div>
                <div className="serve-faq-content">
                  <div className="serve-faq-list">
                    {faq?.map((item, index) => (
                      <FAQ
                        key={item?.id + "-" + index}
                        question={item?.question}
                        answer={item?.answer}
                      />
                    ))}
                  </div>
                  <BookDiscussion
                    title={"Book in a discussion"}
                    link={"/request-a-demo"}
                    button={"Request A Demo"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default WhoWeServe;
